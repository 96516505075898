.chat-widget {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    max-width: 300px;
    padding: 10px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000; /* High z-index to ensure it appears above other content */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
  
  .messages {
    height: 250px;
    overflow-y: auto;
    margin-bottom: 10px;
  }
  
  .message {
    display: flex;
    align-items: flex-start;
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
  }
  
  .message.user {
    background-color: #24224A;
    align-self: flex-start;
  }
  
  .message.ai {
    background-color: #E40E51;
    align-self: flex-end;
  }
  
  .icon-container {
    margin-right: 10px;
  }
  
  .icon-background {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .ai-background {
    background-color: #24224A;
  }
  
  .user-background {
    background-color: #e3e3e3;
  }
  
  .icon-container img {
    width: 20px;
    height: 20px;
  }
  
  .message-text {
    flex-grow: 1;
  }
  
  .message-form {
    display: flex;
    background-color: #e3e3e3;
    border-radius: 10px;
    padding: 5px;
    padding-top: 10px;
  }
  
  .search_input {
    flex-grow: 1;
    padding: 5px;
    margin-right: 5px;
    border-radius: 12px;
    border: 1.5px solid lightgrey;
    outline: none;
  }
  
  .button-17 {
    padding: 5px 15px;
    margin-right: 10px;
  }
  
/* Loader */
.dot-pulse {
    margin-left: 20px;
    margin-top: 10px;
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #000000;
    color: #06033b;
    box-shadow: 9999px 0 0 -5px;
    animation: dot-pulse 1.5s infinite linear;
    animation-delay: 0.25s;
  }
  .dot-pulse::before, .dot-pulse::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #06033b;
    color: #06033b;
  }
  .dot-pulse::before {
    box-shadow: 9984px 0 0 -5px;
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
  }
  .dot-pulse::after {
    box-shadow: 10014px 0 0 -5px;
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
  }
  
  @keyframes dot-pulse-before {
    0% {
      box-shadow: 9984px 0 0 -5px;
    }
    30% {
      box-shadow: 9984px 0 0 2px;
    }
    60%, 100% {
      box-shadow: 9984px 0 0 -5px;
    }
  }
  @keyframes dot-pulse {
    0% {
      box-shadow: 9999px 0 0 -5px;
    }
    30% {
      box-shadow: 9999px 0 0 2px;
    }
    60%, 100% {
      box-shadow: 9999px 0 0 -5px;
    }
  }
  @keyframes dot-pulse-after {
    0% {
      box-shadow: 10014px 0 0 -5px;
    }
    30% {
      box-shadow: 10014px 0 0 2px;
    }
    60%, 100% {
      box-shadow: 10014px 0 0 -5px;
    }
  }

  .toggle-widget {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 2px;
    font-size: 20px;
  }
  
  .chat-widget.collapsed {
    width: 20px;  /* Or any appropriate size */
    height: 20px; /* Adjust as needed */
    /* Adjust other styles as needed */
  }
  